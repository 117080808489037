.heatmap{
    height: 400px;
    position:relative;
}

.no-orders{
    color:#777 !important;
    height: 400px;
    position:relative;
    margin-left: 40%;
}

.spinner{
    margin-left: 50%;
    margin-top: 20%;
}

.rdw-editor-main{
    height: 350px;
    
}

@media (max-width: 600px) {
    .heatmap{
        height: 250px;
        margin: 1px;
    }
    .no-orders{
        font-size: 12px;
        margin-left: 30%;
    }

    .spinner{
        margin-left: 45%;
        margin-top: 30%;
    }
  }