.tooltip-btn {
    font-weight: 700;
    font-family: monospace;
    color: white;
    background: #20A5d6;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    float: right;
    margin-left: 15px;
}